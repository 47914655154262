import React from "react";
import wrapperComponent from "./Common/wrapperComponent";
import ReadMoreReadLess from "./Common/ReadMoreReadLess";
import { data } from "../data/constant";

const Introduction = ({ isMobileDevice }) => {
  return (
    <div className="w-full mt-10 max-[768px]:px-[40px] max-[425px]:px-[10px]">
      <div className="max-w-[1136px] p-10 mx-auto bg-[#264de40a] flex flex-col gap-[10px] rounded-xl max-[768px]:max-w-[768px] max-[425px]:px-[20px]">
        <h1 className="text-[#264DE4] text-[32px] tracking-[6.4px] leading-[40px] max-[425px]:text-[20px]">
          Introduction
        </h1>
        <p className="text-[24px] leading-[40px] translate-[0.24px] max-[768px]:text-[20px] max-[425px]:text-[12px] max-[425px]:leading-[20px]">
          {data.introduction}
        </p>
      </div>
    </div>
  );
};

export default wrapperComponent(Introduction);
