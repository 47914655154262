import React from "react";
import Slider from "react-slick";
import ReadMoreReadLess from "./Common/ReadMoreReadLess";
import wrapperComponent from "./Common/wrapperComponent";
import { data } from "../data/testimonials";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = ({ isMobileDevice }) => {

  const settings = {
    touchMove: true,
    infinite: true,
    autoplaySpeed: 4000,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    arrows: false,
  };
  return (
    <div className="w-full mt-10 testimonials max-[425px]:hidden">
      <div className="max-w-[1136px] mx-auto flex flex-col gap-[20px] max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[425px]:px-[20px]">
        <h1 className="text-[#264DE4] font-[500] text-[32px] leading-[40px] tracking-[6.4px]">
          Testimonials
        </h1>
        <Slider {...settings}>
          {data.map(({ image, id, name, designation, testimonial }) => (
            <div
              key={id}
              className="py-[40px] px-[60px] shadow-lg flex flex-col gap-[40px] rounded-2xl"
            >
              {isMobileDevice ? (
                <ReadMoreReadLess>{testimonial}</ReadMoreReadLess>
              ) : (
                <p className="text-[24px] leading-[40px] translate-[0.24px] max-[768px]:text-[20px]">
                  {testimonial}
                </p>
              )}
              <div className="flex gap-[10px] items-center">
                <img
                  loading="lazy"
                  className="h-[60px] w-[60px] rounded-[50%] object-fill"
                  src={image}
                  alt="Profile"
                />
                <div>
                  <p className="text-[24px] font-[500] tracking-[0.24px] text-[#343A3F]">
                    {name}
                  </p>
                  <p className="text-[#6B757D] text-[18px] tracking-[0.18px]">
                    {designation}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default wrapperComponent(Testimonials);
