import React, { useRef } from "react";
import Slider from "react-slick";
import { FiArrowUpRight } from "react-icons/fi";
import ReactPlayer from "react-player";
import rightArrow from "../assets/right_arrow.svg";
import leftArrow from "../assets/left_arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { videos } from "../data/youtube";
import { openExternalUrl } from "../utils";
import { socialLinks } from "../utils/constants";

const Youtube = () => {
  const slider = useRef(null);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="w-full youtube bg-[#de3e440a] mt-10 py-[54px]">
      <div className="max-w-[1136px] mx-auto flex flex-col gap-10 max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[425px]:px-[20px] max-[425px]:gap-[32px]">
        <div className="flex justify-between max-[768px]:flex-col max-[768px]:gap-[10px]">
          <h1 className="text-[32px] font-[500] leading-[40px] tracking-[6.4px] text-[#264DE4] max-[425px]:text-[20px] max-[425px]:leading-[32px]">
            Our Latest Youtube Videos
          </h1>
          <p 
            className= "border-[1px] border-solid border-[#DE3E44] py-[12px] px-[24px] bg-[#FEF7F8] rounded-[4px] text-[#DE3E44] text-[18px] leading-[0.18px] flex justify-center items-center cursor-pointer max-[768px]:max-w-[230px] max-[425px]:hidden"
            onClick={()=> openExternalUrl(socialLinks.YOUTUBE)}
          >
            Watch on youtube
            <span>
              <FiArrowUpRight className="ml-[10px]" />
            </span>
          </p>
        </div>
        <div>
          <Slider ref={slider} {...settings}>
            {videos.map((video) => (
              <div key={video.id}>
                <ReactPlayer
                  url={video.url}
                  controls={true}
                  playing={true}
                  light={true}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            ))}
          </Slider>
          <div className="flex justify-center gap-[30px] mt-[20px]">
            <button onClick={() => slider?.current?.slickPrev()}>
              <img src={leftArrow} alt="Prev" />
            </button>
            <button onClick={() => slider?.current?.slickNext()}>
              <img src={rightArrow} alt="Next" />
            </button>
          </div>
          <div className="hidden max-[425px]:flex max-[425px]:mt-[25px] max-[425px]:justify-center">
            <p className="border-[1px] border-solid border-[#DE3E44] py-[12px] px-[24px] bg-[#FEF7F8] rounded-[4px] text-[#DE3E44] text-[18px] leading-[0.18px] flex justify-center items-center cursor-pointer max-[768px]:max-w-[230px]">
              Watch on youtube
              <span>
                <FiArrowUpRight className="ml-[10px]" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
