import React from "react";
import wrapperComponent from "./Common/wrapperComponent";
import ReadMoreReadLess from "./Common/ReadMoreReadLess";
import { data } from '../data/constant';

const MissionVision = ({ isMobileDevice }) => {

  return (
    <div className="w-full flex mt-10">
      <div className="max-w[1136px] mx-auto flex gap-[20px] max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[768px]:flex-col max-[425px]:px-[10px]">
        <div className="max-w-[556px] mx-auto p-[40px] rounded-xl bg-[#1ba3450a] flex flex-col gap-[10px] max-[768px]:max-w-[100%] max-[425px]:px-[20px]">
          <h1 className="text-[32px] text-[#1BA345] leading-[40px] tracking-[6.4px] max-[425px]:text-[20px]">Mission</h1>      
          <p className="text-[24px] leading-[40px] translate-[0.24px] max-[768px]:text-[20px] max-[425px]:text-[12px] max-[425px]:leading-[20px]">
            {data.mission}
          </p>
        </div>
        <div className="max-w-[556px] mx-auto p-[40px] rounded-xl bg-[#fec0010a] flex flex-col gap-[10px] max-[768px]:max-w-[100%] max-[425px]:px-[20px]">
          <h1 className="text-[32px] text-[#FEC001] leading-[40px] tracking-[6.4px] max-[425px]:text-[20px]">Vision</h1>
          <p className="text-[24px] leading-[40px] translate-[0.24px] max-[768px]:text-[20px] max-[425px]:text-[12px] max-[425px]:leading-[20px]">
            {data.vision}
          </p>
        </div>
      </div>
    </div>
  );
};

export default wrapperComponent(MissionVision);
