import React from "react";
import hero from '../assets/main.svg';
import { forms } from "../data/form";

const Main = () => {
  return (
    <div className="w-full mt-10 max-[425px]:mt-0">
      <div className="max-w-[1136px] shadow-lg px-[40px] py-[40px] rounded-lg mx-auto h-[600px] relative max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[768px]:h-[500px] max-[425px]:h-[310px] max-[425px]:px-[0px] max-[425px]:py-[0px]">
        <img
          loading="lazy"
          className="h-full w-[55%] float-right max-[425px]:hidden"
          src={hero}
          alt=""
        />
        <p className="hidden h-[100%] w-[100%] max-[425px]:block max-[425px]:bg-[#264de414]"></p>
        <div className="absolute top-[20%] max-w-[60%] max-[425px]:max-w-[100%] max-[425px]:top-[5%] max-[425px]:px-[20px]">
          <h1 className="text-[#343A3F] text-[64px] max-[768px]:text-[40px] max-[425px]:text-[32px]">
            Empowering <br />{" "}
            <span className="text-[#264DE4]">{`{Developers}`}</span> to <br />{" "}
            write quality <span className="text-[#264DE4]">{`{code}`}</span>
          </h1>
          <a href={forms.prRequest} target="_blank" rel="noreferrer">
            <p className="max-w-[250px] mt-[30px] bg-[#264DE4] text-white rounded-[8px] cursor-pointer h-[54px] flex justify-center items-center py-[17px] px-[36px] border-[1px] border-blue max-[768px]:px-[13px] max-[768px]:py-[10px] max-[768px]:max-w-[210px]">
              Request a Code Review
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Main;
