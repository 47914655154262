import sanambir from "../assets/sanambir.jpg";
import narotam from '../assets/narotam.jpg'
import akshay from '../assets/akshay.jpg';

export const data = [
  {
    id: 1,
    name: "Sanambir",
    testimonial: `First of all kudos to the team doing great job in code reviewing. 
                        In my experience honestly I was unaware about the code quality and 
                        standards, but the session gave me lots of insights and enlightened 
                        me with good coding practices. I would recommend everyone to go through 
                        this sessions.`,
    image: sanambir,
    designation: "Aspiring Developer",
  },
  {
    id: 2,
    name: "Akshay vinayak",
    testimonial: `Code Review: I am glad that I came across such an excellent platform. 
                   I never thought that writing code can be so interesting. Going from 
                   writing code like a vagabond to clean readable and precise coding; 
                   this platform has helped me a lot. Industry experts review your code, 
                   giving you proper advice, and grafting your path to becoming a pro coder.`,
    image: akshay,
    designation: "SDE@ Liquid",
  },
  {
    id: 3,
    name: "Narotam Mishra",
    testimonial: `Code review is an important process that helps improve the quality of code. 
                I am grateful for the platform that allows me to receive feedback and catch any 
                issues in my code. It promotes teamwork, finding and fixing mistakes, making 
                the code run better, and sharing knowledge with others. This leads to better-quality 
                code overall.`,
    image: narotam,
    designation: "SDE@ Capgemini",
  },
];
