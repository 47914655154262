export const videos = [
    {
        url: "https://www.youtube.com/watch?v=9xYs0VgIrZI",
        id: 1,

    },
    {
        url: "https://www.youtube.com/watch?v=7-TON6dPYYg",
        id: 2,
    },
    {
        url: "https://www.youtube.com/watch?v=m5e4rPPgEW4&t=2230s",
        id: 3,
    },
    {
        url: "https://www.youtube.com/watch?v=m7pt_XWOqB0",
        id: 4,
    },
    {
        url: "https://www.youtube.com/watch?v=u4lDyzmQmoE&t=1s",
        id: 5,
    },
    {
        url: "https://www.youtube.com/watch?v=wOmznW-NSNM",
        id: 6,
    },
    {
        url: "https://www.youtube.com/watch?v=o0HqYmJxkEg",
        id: 7,
    },
    {
        url: "https://www.youtube.com/watch?v=2N5q95iZ6Ow&t=17s",
        id: 8,
    },
    {
        url: "https://www.youtube.com/watch?v=Z3Q8SU53D8s&t=14s",
        id: 9,
    },
    {
        url: "https://www.youtube.com/watch?v=CbgmP4s_UAo",
        id: 10,
    },
    {
        url: "https://www.youtube.com/watch?v=ceVT6yZ6HqI&t=5s",
        id: 11,
    },
    {
        url: "https://www.youtube.com/watch?v=6z-Wy825Kh8",
        id: 12,
    }
]