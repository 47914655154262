import React from 'react';
import Header from './Components/Header';
import Main from './Components/Main';
import Introduction from './Components/Introduction';
import MissionVision from './Components/MissionVision';
import Youtube from './Components/Youtube';
import Developers from './Components/Developers';
import Testimonials from './Components/Testimonials';
import Request from './Components/Request';
import Footer from './Components/Footer';

const App = () => {
  return (
    <div>
      <Header />
      <Main />
      <Introduction />
      <MissionVision />
      <Youtube />
      <Developers />
      <Testimonials />
      <Request />
      <Footer />
    </div>
  )
}

export default App