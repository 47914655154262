import React from 'react';
import { FaYoutube, FaLinkedin, FaGithub, FaTelegram } from 'react-icons/fa';
import { openExternalUrl } from '../utils';
import {socialLinks} from '../utils/constants';

const Footer = () => {
  return (
    <div className='w-full bg-[#343A3F] h-[80px] max-[425px]:py-[24px] max-[425px]:h-auto'>
        <div className='max-w-[1136px] h-[100%] mx-auto flex justify-between items-center max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[425px]:px-[20px] max-[425px]:py-[7px] gap-[10px] max-[425px]:flex-col-reverse max-[425px]:gap-[20px]'>
            <div className='text-white font-[500] text-[18px] tracking-[0.18px] max-[425px]:text-[12px]'>
                <p className='max-[425px]:text-center'>© 2023 Codereview. All rights reserved.</p>
            </div>
            <div className='flex gap-[20px] text-white max-[425px]:justify-between max-[425px]:gap-[45px]'>
                <FaYoutube className='cursor-pointer text-[22px]'onClick={() => openExternalUrl(socialLinks.YOUTUBE)} />
                <FaLinkedin className='cursor-pointer text-[22px]'onClick={() => openExternalUrl(socialLinks.LINKEDIN)} />
                <FaGithub className='cursor-pointer text-[22px]'onClick={() => openExternalUrl(socialLinks.GITHUB)} />
                <FaTelegram className='cursor-pointer text-[22px]'onClick={() => openExternalUrl(socialLinks.TELEGRAM)}/>
            </div>
        </div>
    </div>
  )
}

export default Footer