import anshu from '../assets/anshu.jpg';
import sandeep from '../assets/sandeep.jpg';
import sudhendra from '../assets/sudhendra.jpg';
import ashutosh from '../assets/ashutosh.jpg';

export const data = [
    {
        id: 1,
        image: anshu,
        name: "Anshu Rai",
        designation: "SDE@ Postman",
        github: "https://github.com/Anshu-rai89",
        linkedIn: "https://www.linkedin.com/in/anshu-rai-4aa76317b/",
    },
    {
        id: 2,
        image: sudhendra,
        name: "Sudhendra Singh",
        designation: "SDE@ Turing",
        github: "https://github.com/rockingatgithub",
        linkedIn: "https://www.linkedin.com/in/sudhendra-singh-168831130/",
    },
    {
        id: 3,
        image: ashutosh,
        name: "Ashutosh Rai",
        designation: "SDE@ Slate.ai",
        github: "https://github.com/ashurajchandra",
        linkedIn: "https://www.linkedin.com/in/ashutosh-rai-712152117/",
    },
    {
        id: 4,
        image: sandeep,
        name: "Sandeep Shah",
        designation: "SDE@ RootQuotient",
        github: "https://github.com/Sandeep96Shah",
        linkedIn: "https://www.linkedin.com/in/sandeep96shah/",
    },
]