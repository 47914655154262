export const data = {
  introduction: `Welcome to Code Review, the ultimate platform for developers seeking to enhance their coding skills and produce top-notch, high-quality code. 
  At Code Review, we understand that writing exceptional code is not just a skill; 
  It's an art form that requires continuous learning, feedback, and collaboration.
  
  `,
  mission: `Our mission is to build a platform which is designed to empower developers of all levels to engage in code reviews that go beyond mere error spotting. 
  We believe that code reviews should be an opportunity for growth, a chance to learn from each other, and a means to foster a culture of excellence within development teams.`,
  vision: `Our vision is to create a vibrant and inclusive learning community
    where code reviews are not just a formality, but a catalyst for progress. 
    Elevate your coding prowess, gain valuable insights, and help others write quality code – all while contributing to a thriving community of passionate developers.`,
};
