import React from "react";
import { forms } from "../data/form";

const Request = () => {
  return (
    <div className="w-full h-[380px] mt-12 relative flex justify-center max-[768px]:h-[320px] max-[425px]:h-[260px]">
      <img
        loading="lazy"
        className="h-[380px] w-[100%] max-[768px]:h-[320px] max-[425px]:h-[260px]"
        src="https://img.freepik.com/free-vector/blue-data-technology-background-vector-with-binary-code_53876-110871.jpg"
        alt=""
      />
      <div className="h-[100%] w-[1136px] mx-auto absolute flex justify-start max-[768px]:max-w-[768px] max-[425px]:w-[100%]">
        <div className="absolute text-white w-[100%] top-[30%] flex justify-between items-center  max-[768px]:flex-col max-[768px]:items-start max-[768px]:px-[40px] max-[768px]:top-[20%] max-[768px]:gap-[15px] max-[425px]:top-[25%] max-[425px]:px-[15px] max-[425px]:gap-[20px]">
          <p className="text-white text-[54px] leading-[84px] max-[768px]:leading-[60px] max-[768px]:text-[45px] max-[768px]:max-w-[100%] max-[425px]:text-[24px] max-[425px]:leading-[32px]">
            Elevate Your Code with <br className="max-[768px]:hidden" /> a
            Professional Checkup!
          </p>
          <div className="max-[425px]:flex max-[425px]:justify-center max-[425px]:w-[100%]">
            <a className="max-[425px]:w-[100%]" href={forms.prRequest} target="_blank" rel="noreferrer">
              <p className="py-[17px] px-[36px] bg-white text-[#264DE4] rounded-md text-[18px] font-[500] tracking-[0.18px] cursor-pointer max-[768px]:py-[10px] max-[768px]:px-[13px] max-[425px]:flex max-[425px]:justify-center">
                Request a Code Review
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
