import React, { useState } from "react";

const ReadMoreReadLess = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(false);

  return (
    <p className="text-[24px] leading-[40px] translate-[0.24px] max-[768px]:text-[20px]">
      {isReadMore ? children : children.slice(0,100)}
      <span className="cursor-pointer text-[#264DE4]" onClick={() => setIsReadMore(!isReadMore)}>
        {isReadMore ? "...Read Less" : "...Read More"}
      </span>
    </p>
  );
};

export default ReadMoreReadLess;
