import React from "react";
import { forms } from '../data/form';
import logo from "../assets/logo.jpg";

const Header = () => {
  return (
    <div className="w-full h-[84px] flex items-center shadow-lg">
      <div className="flex items-center justify-between w-[1136px] mx-auto max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[425px]:px-[20px] max-[375px]:px-[10px]">
        <div className="flex items-center">
          <img
            loading="lazy"
            className="w-[70px] h-[70px] mr-[10px] rounded-xl max-[425px]:h-[55px] max-[425px]:w-[55px] max-[375px]:w-[50px] max-[375px]:h-[50px]"
            src={logo}
            alt="LOGO"
          />
          <h1 className="text-[24px] font-medium leading-10 tracking-wide text-[#343A3F] max-[425px]:text-[22px] max-[375px]:text-[18px]">
            Code Review
          </h1>
        </div>
        <div>
          <a href={forms.contactUs} target="_target">
            <p className="py-[5px] px-[15px] rounded-xl cursor-pointer text-[#264DE4] border-solid border-[#264DE4] border-[1px] text-[22px] max-[425px]:text-[18px]">
              Contact <span className="max-[375px]:hidden">Us</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
