import React from "react";
import Slider from "react-slick";
import { CiLinkedin } from "react-icons/ci";
import { FaGithub } from "react-icons/fa";
import { data } from "../data/developers";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Developers = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
  };
  return (
    <div className="w-full mt-10 developers">
      <div className="w-[1136px] mx-auto flex flex-col gap-[50px] max-[768px]:max-w-[768px] max-[768px]:px-[40px] max-[425px]:w-[100%] max-[425px]:px-[20px]">
        <h1 className="text-[32px] tracking-[6.4px] leading-[40px] text-[#264DE4] max-[425px]:text-[20px] max-[425px]:leading-[32px]">
          Our Awesome Team Of Developers
        </h1>
        <Slider {...settings}>
          {data.map((item) => (
            <div className="flex flex-col relative" key={item.id}>
              <p className="h-[65px] w-[65px] absolute top-[-35px] right-[8%] origin-center rotate-45 bg-white max-[425px]:top-[-44px] max-[425px]:right-[-8px]"></p>
              <div className="absolute top-[50px] right-[8%] flex flex-col gap-[10px] items-center text-[grey] max-[425px]:right-[0%]">
                <a href={item.linkedIn} target="_blank" rel="noreferrer">
                  <CiLinkedin className="cursor-pointer" size={25} />
                </a>
                <a href={item.github} target="_blank" rel="noreferrer">
                  <FaGithub className="cursor-pointer" size={25} />
                </a>
              </div>
              <img
                loading="lazy"
                className="h-[300px] mb-[5px] rounded-lg w-[80%] max-[425px]:h-[366px] max-[425px]:w-[90%]"
                src={item.image}
                alt="Developer"
              />
              <p className="text-[24px] text-[#343A3F] font-[500] max-[425px]:text-[22px]">{item.name}</p>
              <p className="text-[18px] text-[#6B757D] max-[425px]:text-[16px]">{item.designation}</p>
              <p className="h-[150px] w-[2px] border-[1px] border-[#343A3F80] absolute right-[11%] bottom-[68px] max-[425px]:right-[3%]"></p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Developers;
