import React, { useState, useEffect } from 'react'

const wrapperComponent = (Component) => {
    const WrapperComponent = () => {
        const [isMobileDevice, setIsMobileDevice] = useState(
            window.innerWidth <= 425 ? true : false
          );
        
          const handleMobileDevice = () => {
            setIsMobileDevice(window.innerWidth <= 425 ? true : false);
          }
        
          useEffect(() => {
            window.addEventListener('resize', handleMobileDevice);
          })
      return ( <Component isMobileDevice={isMobileDevice} /> )
    }

    return WrapperComponent;
}

export default wrapperComponent